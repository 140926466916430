import { configureStore } from '@reduxjs/toolkit'
import userReducer from './user/userSlice'
import adminSlice from './user/adminSlice'

export const store = configureStore({
  reducer: {
    user: userReducer,
    admin: adminSlice
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;

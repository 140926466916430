import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

function getCurrentTime(date: Date): string {
  const currentDate = date

  const timeOptions: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric', hour12: true }

  const formattedTime: string = currentDate.toLocaleTimeString('en-US', timeOptions)

  return formattedTime
}

function shortenWalletAddress(walletAddress: string, startChars = 6, endChars = 4) {
  if (!walletAddress) return ''

  const prefix = walletAddress.slice(0, startChars)
  const suffix = walletAddress.slice(-endChars)

  return `${prefix}...${suffix}`
}
function validateEmail(email: string) {
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  return regex.test(email)
}

function capitalizeFirstChar(str: string) {
  if (!str) return str
  return str.charAt(0).toUpperCase() + str.slice(1)
}

function cn(...classNames: any) {
  return classNames.filter(Boolean).join(' ')
}
export { getCurrentTime, shortenWalletAddress, validateEmail, capitalizeFirstChar, cn }

export function shadcn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function truncateAddress(addr: string, starting?: number, ending?: number): string {
  if (!addr || addr === '-') return '-'
  if (starting && ending) {
    if (addr.length <= starting + ending) return addr
    return `${addr.slice(0, starting)}...${addr.slice(-ending)}`
  } else {
    if (addr.length <= 6 + 4) return addr
  }

  return `${addr.slice(0, 6)}...${addr.slice(-4)}`
}

import React, { useEffect, useState } from 'react'
import { useSettings } from 'src/@core/hooks/useSettings'
import { Mode } from 'src/@core/layouts/types'
import { Moon, Sun } from 'lucide-react'

const CustomToggleButton: React.FC = () => {
  const [isDarkMode, setIsDarkMode] = useState(false)
  const { settings, saveSettings } = useSettings()

  useEffect(() => {
    if (settings.mode === 'light') {
      setIsDarkMode(false)
    } else {
      setIsDarkMode(true)
    }
  }, [settings.mode])

  const handleModeChange = (mode: Mode) => {
    saveSettings({ ...settings, mode: mode })
  }

  const handleModeToggle = () => {
    if (settings.mode === 'light') {
      handleModeChange('dark' as Mode)
      setIsDarkMode(true)
    } else {
      handleModeChange('light' as Mode)
      setIsDarkMode(false)
    }
  }

  return (
    <div className='flex items-center space-x-2'>
      <button
        onClick={handleModeToggle}
        className='relative flex h-8 w-8 items-center justify-center rounded-full border border-border bg-background mr-2'
        aria-label='Toggle theme'
      >
        <Sun
          className={`h-[1.2rem] w-[1.2rem] absolute transition-all duration-500
            ${isDarkMode ? 'rotate-90 scale-0 opacity-0' : 'rotate-0 scale-100 opacity-100'}`}
        />
        <Moon
          className={`h-[1.2rem] w-[1.2rem] absolute transition-all duration-500
            ${isDarkMode ? 'rotate-0 scale-100 opacity-100' : '-rotate-90 scale-0 opacity-0'}`}
        />
      </button>
      <span className='sr-only'>Switch Theme</span>
    </div>
  )
}

export default CustomToggleButton

import axios, { AxiosInstance } from 'axios'
import authConfig from './auth'
import { store } from 'src/redux/store'

// Function to create a new Axios instance
export const createAxiosInstance = (clientId: string): AxiosInstance => {
  // Define the base URL for API requests

  const baseURL = process.env.NEXT_PUBLIC_BACKEND_URL;
  // const baseURL = 'https://prod.tria.so'
  // const baseURL = 'http://localhost:8000'
  // const baseURL = 'https://sl-prod.tria.so'

  const axiosInstance: AxiosInstance = axios.create({
    baseURL: `${baseURL}/api/v2`, // Base URL for all requests
    headers: {
      'Content-Type': 'application/json' // Default content type
    }
  })

  // Add a request interceptor  to include the access token in the headers
  axiosInstance.interceptors.request.use(
    config => {
      if (typeof window !== 'undefined') {
        const token = window.localStorage.getItem('accessToken')
        if (token) {
          config.headers.Authorization = `Bearer ${token}`
        }
        // if (clientId) {
        //   config.headers.organizationId = clientId
        // }
      }
      return config
    },
    error => Promise.reject(error)
  )

  // Add a response interceptor to handle 401 errors
  axiosInstance.interceptors.response.use(
    response => {
      if (response?.data?.token) {
        // localStorage.setItem('userData', JSON.stringify(response.data?.userData));
        window.localStorage.setItem('accessToken', response.data.token)
      }
      return response
    },
    error => {
      if (typeof window !== 'undefined') {
        console.log('error------------>', error)
        if (error.response && error.response.status === 401) {
          // Call your logout function here
          console.log('calling logout')
          window.localStorage.removeItem('userData')
          window.localStorage.removeItem(authConfig.storageTokenKeyName)
          console.log(window.location)
          if (window.location.pathname !== '/auth/' && window.location.pathname !== '/auth')
            window.location.reload()
          // router.push('/login')
        }
      }
      return Promise.reject(error)
    }
  )

  return axiosInstance
}

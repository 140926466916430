// ** MUI Imports
import React, { useEffect } from 'react'
import { Theme, useTheme } from '@mui/material/styles'
import {
  FormControl,
  Select as MuiSelect,
  OutlinedInput,
  MenuItem,
  InputBase,
  ToggleButtonGroup,
  ToggleButton,
  Dialog
} from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'
import { useState } from 'react'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
// ** Icon Imports
import Icon from 'src/@core/components/icon'
import WbSunnyIcon from '@mui/icons-material/WbSunny'
import Brightness3Icon from '@mui/icons-material/Brightness3'
// ** Type Import
import { Settings } from 'src/@core/context/settingsContext'

// ** Components
import UserDropdown from 'src/@core/layouts/components/shared-components/UserDropdown'
// ** Hook Import
import { useAuth } from 'src/hooks/useAuth'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import {
  changeTheme,
  updateAllProjects,
  updateSelectedEnviornment,
  updateSelectedProject,
  updateSelectedProjectDetails
} from 'src/redux/user/userSlice'
import { useAxios } from 'src/hooks/useAxios'
import ClientsDropdownMenu from 'src/views/client/clientsDropDownMenu'
import ProjectDetails from 'src/views/overview/ProjectDetails'
import SelectTechstack from 'src/views/overview/SelectTechstack'
import { RootState } from 'src/redux/store'
import { ProjectType } from 'src/types/apps/userTypes'
import toast from 'react-hot-toast'
import { Switch } from '@headlessui/react'
import { useSettings } from 'src/@core/hooks/useSettings'
import { ProjectDetailsForm } from 'src/types'
import WithPermission from 'src/pages/components/hoc/WithPermission'

// ** Import shadcn Select Components
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '../../../components/shadcn/select'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../../../components/shadcn/tooltip'
import { SheetMenu } from 'src/components/new-sidebar/sheet-menu'
import { truncateAddress } from 'src/@core/utils/helper-functions'

interface Props {
  hidden: boolean
  settings: Settings
  toggleNavVisibility: () => void
  saveSettings: (values: Settings) => void
}

interface Project {
  id: string
  environments: Record<string, { id: string }>
}

const defaultProjectDetails = {
  name: '',
  imageUrl: '',
  techStack: undefined,
  title: 'Create New Project',
  button: 'Create Project',
  whitelistedOrigin: []
}

const AppBarContent = (props: Props) => {
  // ** Props
  const { hidden, settings, saveSettings, toggleNavVisibility } = props
  const userData = useSelector((state: any) => state.user.userProfileData)

  // ** Hook
  const auth = useAuth()
  const theme = useTheme()
  const environment = useSelector((state: RootState) => state.user.selectedEnviornment)
  const axiosInstance = useAxios()
  const dispatch = useDispatch()

  //Selectors
  const allProject = useSelector((state: RootState) => state.user.allProjects)
  const allOption = {
    id: 'all',
    name: 'All Projects',
    imageUrl: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    live: '',
    sandbox: '',
    techStack: undefined,
    whitelistedOrigin: [],
    organizationId: ''
  }

  const allProjects = [allOption, ...allProject]
  const selectedProject = useSelector((state: RootState) => state.user.selectedProject)
  const selectedProjectId = useSelector((state: RootState) => state.user.selectedProjectId)

  const [openSelectTech, setOpenSelectTech] = useState(false)
  const [openProjectDetails, setOpenProjectDetails] = useState(false)
  const [variant, setVariant] = useState('')
  const [darkMode, setDarkMode] = useState(false)
  const [projectDetails, setProjectDetails] = useState<ProjectDetailsForm>(defaultProjectDetails)

  const { mode } = settings

  useEffect(() => {
    const darkmode = mode == 'dark' ? true : false
    setDarkMode(darkmode)
  }, [mode])

  useEffect(() => {
    if (openProjectDetails === false) {
      setProjectDetails(defaultProjectDetails)
    }
  }, [openProjectDetails])

  useEffect(() => {
    if (userData?.role === undefined) return

    if (userData?.organization?.createdFirstProject !== true) {
      setOpenSelectTech(true)
      setVariant('FirstProject')
    } else {
      setOpenSelectTech(false)
      setVariant('')
    }
  }, [userData])

  const handleChange = (): void => {
    setDarkMode(!darkMode)
    saveSettings({ ...settings, mode: darkMode == true ? 'dark' : 'light' })
  }

  const handleEnvironmentChange = (value: 'live' | 'sandbox') => {
    console.log("value--->",value);
    localStorage.setItem("ENVIRONMENT", value);
    dispatch(updateSelectedEnviornment(value))
  }

  const handleProjectChange = (projectId: string) => {
    const project = allProjects.find(p => p.id === projectId)
    if (!project) return

    dispatch(
      updateSelectedProject({
        ...project,
        createdAt: project.createdAt ? new Date(project.createdAt) : new Date(),
        updatedAt: project.updatedAt ? new Date(project.updatedAt) : new Date(),
        techStack: project.techStack as 'React' | 'Web' | 'Native' | undefined
      })
    )
    //@ts-ignore
    const environmentId = project[environment]
    if (environmentId) {
      dispatch(updateSelectedProjectDetails(environmentId))
    }
  }

  const fetchProjectData = async () => {
    try {
      const response = await auth.getProjectsData()
      if (response.success) {
        dispatch(updateAllProjects(response.response))
        if (!selectedProjectId) {
          dispatch(updateSelectedProject(allOption))
        }
      }
    } catch (error) {
      toast(`${error}`)
    }
  }

  useEffect(() => {
    fetchProjectData()
  }, [axiosInstance])

  // Dark mode
  const storedSettings = JSON.parse(window.localStorage.getItem('settings') || '{}')

  useEffect(() => {
    dispatch(changeTheme(storedSettings.mode))
  }, [storedSettings])

  return (
    <div className='w-[92vw] -z-20 fixed top-0  bg-white justify-between items-center inline-flex -ml-3'>
      <SheetMenu />
      {/* here i added sheet */}
      <div className='w-full self-stretch justify-between items-center gap-1.5 flex cursor-pointer'>
        {userData?.role && (
          <>
            {userData?.role?.name == 'triaadmin' ? (
              <ClientsDropdownMenu />
            ) : (
              <>
                {allProjects.length > 0 ? (
                  <div className='flex items-center gap-4 ml-2'>
                    <Select value={selectedProject?.id} onValueChange={handleProjectChange}>
                      <SelectTrigger className='w-[120px] md:w-[200px] overflow-hidden'>
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger>
                              <SelectValue placeholder='Select Project' />
                            </TooltipTrigger>
                            <TooltipContent className='z-50 md:hidden ' side='bottom' sideOffset={20}>
                              {selectedProject?.name}
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </SelectTrigger>
                      <SelectContent className='bg-white'>
                        <SelectGroup>
                          {allProjects.map(project => (
                            <>
                              <SelectItem
                                key={project.id}
                                value={project.id}
                                className='cursor-pointer hover:bg-gray-300/40'
                              >
                                {project.name}
                              </SelectItem>
                            </>
                          ))}
                          <WithPermission resource='project' action='create'>
                            <div
                              className='flex items-center gap-2 p-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-800'
                              onClick={() => setOpenSelectTech(true)}
                            >
                              <AddOutlinedIcon className='w-4 h-4' />
                              <span>Add Project</span>
                            </div>
                          </WithPermission>
                        </SelectGroup>
                      </SelectContent>
                    </Select>

                    {selectedProject?.id !== 'all' && (
                      <Select value={environment} onValueChange={handleEnvironmentChange}>
                        <SelectTrigger
                          className={`w-[100px] md:w-[200px] ${
                            environment === 'sandbox'
                              ? 'bg-red-200 dark:bg-[#382618]'
                              : environment === 'live'
                              ? 'bg-green-200 dark:bg-[#193e23]'
                              : ''
                          }`}
                        >
                          <SelectValue>
                            <div className='flex items-center gap-2'>
                              {/* <div
                                className={`w-2 h-2 rounded-full ${
                                  environment === 'sandbox' ? 'bg-red-600 dark:bg-[#F6771B]' : 'bg-green-500'
                                }`}
                              /> */}
                              {environment.charAt(0).toUpperCase() + environment.slice(1)}
                            </div>
                          </SelectValue>
                        </SelectTrigger>

                        <SelectContent className='bg-white'>
                          <div className='flex flex-col gap-2'>
                            <SelectItem value='sandbox' className='flex items-center py-2'>
                              <div className='flex items-center gap-2'>
                                <div className='w-2 h-2 rounded-full bg-red-600' />
                                <span>Sandbox</span>
                              </div>
                            </SelectItem>

                            <SelectItem value='live' className='flex items-center py-2'>
                              <div className='flex items-center gap-2'>
                                <div className='w-2 h-2 rounded-full bg-green-500' />
                                <span>Live</span>
                              </div>
                            </SelectItem>
                          </div>
                        </SelectContent>
                      </Select>
                    )}
                  </div>
                ) : (
                  <WithPermission resource='project' action='create'>
                    <div className='flex h-10 w-48 mb-2 mt-2 text-md'>
                      <div
                        className='gap-3 flex justify-start text-white bg-violet-500 px-2 items-center w-[100%] rounded-md'
                        onClick={() => setOpenSelectTech(true)}
                      >
                        <AddOutlinedIcon className='' style={{ fontSize: '1rem' }} />
                        <div className='flex right-0'>Add Project</div>
                      </div>
                    </div>
                  </WithPermission>
                )}
              </>
            )}
          </>
        )}
      </div>

      <div className='py-1.5 justify-end items-center gap-1 md:gap-4 flex'>
        <div
          className='hidden lg:flex p-1.5 rounded-[63.2px] justify-start items-center gap-1.5 cursor-pointer'
          onClick={() => window.open('https://calendly.com/avi-tria/meetup', '_blank')}
        >
          <img
            src={mode == 'light' ? '/images/logos/Frame.svg' : '/images/logos/que_white.png'}
            className='w-4.8 h-4.8 relative text-black'
          />
          <div className="text-black/opacity-80 text-[0.875rem] font-normal font-['Roboto'] leading-snug">
            Need help?
          </div>
        </div>
        <div className='hidden lg:flex p-1.5 rounded-[63.2px] borde justify-start items-center gap-1.5 cursor-pointer'>
          <div className='w-4.8 h-4.8 justify-center items-center flex'>
            <img
              src={mode == 'light' ? '/images/logos/book.svg' : '/images/logos/book_white.png'}
              className='w-4.8 relative'
            />
          </div>
          <div className="hidden md:block text-black/opacity-80 text-[0.875rem] font-normal font-['Roboto'] leading-snug">
            <a href='https://docs.tria.so/' target='blank'>
              Docs
            </a>
          </div>
        </div>
        <div className='p-1.5 rounded-[63.2px] justify-start items-center gap-1.5 flex'>
          <UserDropdown />
        </div>
      </div>

      <Dialog open={openSelectTech} onClose={() => setOpenSelectTech(false)}>
        <SelectTechstack
          setOpenProjectDetails={setOpenProjectDetails}
          handleClose={() => setOpenSelectTech(false)}
          projectDetails={projectDetails}
          setProjectDetails={setProjectDetails}
          setOpenSelectTech={setOpenSelectTech}
        />
      </Dialog>

      <Dialog open={openProjectDetails} onClose={() => setOpenProjectDetails(false)}>
        <ProjectDetails
          projectDetails={projectDetails}
          title='Create new project'
          buttonText='Create Project'
          handleClose={() => {
            setOpenProjectDetails(false)
            setOpenSelectTech(false)
          }}
          variant={variant}
        />
      </Dialog>
    </div>
  )
}

export default AppBarContent

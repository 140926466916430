
import axios from 'axios';
import { Dispatch, SetStateAction, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useAuth } from 'src/hooks/useAuth';
import Loader from 'src/views/loader/Loader';

import { Box, Button, Dialog, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';

interface FileUploadProps {
  setFileUrl: Dispatch<SetStateAction<string>>;
}

const FileUpload = ({ setFileUrl }: FileUploadProps) => {
  const [file, setFile] = useState(null);
  // const [uploadProgress, setUploadProgress] = useState(0);
  const [message, setMessage] = useState('');
  const [uploading, setUploading] = useState(false);
  const auth = useAuth();

  //@ts-ignore
  const onDrop = (acceptedFiles) => {
    console.log("Dropped");
    setUploading(true);
    handleUpload(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleUpload = async (file: File) => {
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await auth.uploadImage(formData);

      // Extract the file URL from the response
      setFileUrl(response.fileUrl);
      setMessage('File uploaded successfully!');
    } catch (error: any) {
      setMessage(error?.message);
      setUploading(false);
      console.error(error);
    }
  };

  const handleBack = (event: any, reason: any) => {
    // setOpenSelectTech(true)
    console.log("Reason", reason);
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    }
    setUploading(false)
  }
 //@ts-ignore
  const mode = useSelector((state: RootState) => state.user.darkMode as string)
  return (
    <div className={`max-w-2xl mx-auto  min-w-[400px]`}>
      {uploading &&
        <Dialog open={uploading} onClose={handleBack} PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }}>
          <Loader />
        </Dialog>}
      <Box
        {...getRootProps()}
        className={` ${mode=='dark' ? "bg-dark":"bg-white"} `}
      
        sx={{
          
          borderRadius: '4px',
          padding: '20px',
          textAlign: 'center',
          //backgroundColor: '#f9f9f9',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '10px'
        }}
      >
        <input {...getInputProps()}/>
        <CloudUploadIcon sx={{ fontSize: 40, color: '#1976d2', }} />
        <Typography variant="body1" >
          Drag 'n' drop some files here, or click to select files
        </Typography>
        <Button variant="contained" color="primary" sx={{ mt: 2 }}>
          UPLOAD
        </Button>
        <p className='text-red-500'>{message}</p>
      </Box>
      {/* // <div className="flex items-center justify-center w-full dark:bg-dark ">
                    //     <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                    //         <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    //             <svg className="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                    //             <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                    //             <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                    //             <p className='text-red-500'>{message}</p>
                    //             <button className='bg-blue-500 text-white px-4 py-3'>Upload</button>
                    //         </div>
                    //         <input id="dropzone-file" type="file" {...getInputProps()} />
                    //     </label>
        // </div> */}

      <script src="https://unpkg.com/flowbite@1.4.0/dist/flowbite.js"></script>
    </div>
    // <div className='w-[50%] mx-auto px-[1rem] py-[1.5rem]'>
    //     <div {...getRootProps({ className: 'dropzone' })}>
    //         <input {...getInputProps()} />
    //         {isDragActive ? (
    //             <p>Drop the files here ...</p>
    //         ) : (
    //             <p>Drag 'n' drop some files here, or click to select files</p>
    //         )}
    //     </div>
    //     {/* <button onClick={handleUpload}>Upload</button> */}
    //     {/* <p>Upload Progress: {uploadProgress}%</p> */}
    //     <p>{message}</p>
    //     {/* {fileUrl && (
    //         <div>
    //             <p>File URL:</p>
    //             <a href={fileUrl} target="_blank" rel="noopener noreferrer">{fileUrl}</a>
    //         </div>
    //     )} */}
    //     {
    //         uploading && (<Loader />)
    //     }
    // </div>
  );
};

export default FileUpload;
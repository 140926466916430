// src/components/WithPermission.tsx
import React from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { User } from 'src/types';
import { hasPermission } from 'src/utils/permissions';

interface WithPermissionProps {
    resource: string;
    action: string;
    children: React.ReactElement;
}

const WithPermission: React.FC<WithPermissionProps> = ({ resource, action, children }) => {
    const user = useSelector((state: any) => state.user.userProfileData);

    const hasPermissionToRender = user && hasPermission(user, resource, action);

    if (!hasPermissionToRender) {
        return null; // Don't render anything if the user doesn't have permission
    }

    return React.cloneElement(children, { onClick: children.props.onClick });
};

export default WithPermission;

import Link from 'next/link'
import { CircleHelp, FileText, PanelsTopLeft } from 'lucide-react'

import { shadcn as cn } from 'src/@core/utils/helper-functions'

import { Button } from '../../components/shadcn/button'

import { SidebarToggle } from './sidebar-toggle'
import Menu from './menu'
import { useStore } from 'src/hooks/use-store'
import { useSidebarToggle } from 'src/hooks/use-sidebar-toggle'
import CustomToggleButton from 'src/views/ui/Switch'

function Sidebar() {
  const sidebar = useStore(useSidebarToggle, state => state)

  if (!sidebar) return null

  return (
    <aside
      className={cn(
        'fixed top-0 left-0 z-50 bg-white dark:bg-[#24303F] h-screen -translate-x-full lg:translate-x-0 transition-[width] ease-in-out duration-300 border-r dark:border-white',
        sidebar?.isOpen === false ? 'w-[90px]' : 'w-[260px]'
      )}
    >
      <SidebarToggle isOpen={sidebar?.isOpen} setIsOpen={sidebar?.setIsOpen} />
      <div className='relative h-full flex flex-col px-3 pt-12 overflow-y-auto shadow-md dark:shadow-zinc-800'>
        {/* <Button
          className={cn(
            'transition-transform ease-in-out duration-300 dark:hidden z-50',
            sidebar?.isOpen === false ? 'translate-x-1' : 'translate-x-0'
          )}
          variant='link'
          asChild
        >
          <Link href='/' className='flex items-center gap-2'>
            <img
              src='/images/logos/tria_logo_black.png'
              alt='dark-logo'
              className={cn('w-20 hidden', sidebar?.isOpen && 'block')}
            />
            <img
              src='/images/logos/tria_logo_black.png'
              alt='dark-short-logo'
              className={cn('w-10 block', sidebar?.isOpen && 'hidden')}
            />
          </Link>
        </Button>
        <Button
          className={cn(
            'transition-transform ease-in-out duration-300 mb-1 hidden dark:flex z-50',
            sidebar?.isOpen === false ? 'translate-x-1' : 'translate-x-0'
          )}
          variant='link'
          asChild
        >
          <Link href='/' className='flex items-center gap-2'>
            <img
              src='/images/logos/tria_logo.png'
              alt='dark-logo'
              className={cn('w-20 hidden', sidebar?.isOpen && 'block')}
            />
            <img
              src='/images/logos/tria_white.png'
              alt='dark-short-logo'
              className={cn('w-10 block', sidebar?.isOpen && 'hidden')}
            />
          </Link>
        </Button> */}
        <Menu isOpen={sidebar?.isOpen} />
        <div className={cn('flex flex-col gap-2 w-full px-2', !sidebar?.isOpen && 'px-2')}>
          <Link href='https://calendly.com/avi-tria/meetup'>
            <Button variant='ghost' className='md:flex items-center justify-start gap-2 w-full hidden'>
              <CircleHelp className='w-5 h-5' />
              {sidebar?.isOpen && 'Need Help'}
            </Button>
          </Link>
          <Link href='https://docs.tria.so/'>
            <Button variant='ghost' className='flex items-center justify-start gap-2 w-full'>
              <FileText className={cn('size-5', !sidebar?.isOpen && 'size-8')} />
              {sidebar?.isOpen && 'Docs'}
            </Button>
          </Link>
          <div
            className={cn(
              'w-full flex items-center justify-start mb-5 text-black dark:text-white ml-3',
              !sidebar.isOpen && 'ml-2'
            )}
          >
            <CustomToggleButton />
            {sidebar?.isOpen && 'Theme'}
          </div>
        </div>
      </div>
    </aside>
  )
}

export default Sidebar

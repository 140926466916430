import React from 'react'
import { useSelector } from 'react-redux'
import IconifyIcon from 'src/@core/components/icon'
import { RootState } from 'src/redux/store'
import { ProjectDetailsForm } from 'src/types'

interface SelectTechstackProps {
  handleClose: (event?: any, reason?: any) => void
  projectDetails: ProjectDetailsForm
  setProjectDetails: React.Dispatch<React.SetStateAction<ProjectDetailsForm>>
  setOpenProjectDetails: React.Dispatch<React.SetStateAction<boolean>>
  setOpenSelectTech: React.Dispatch<React.SetStateAction<boolean>>
}

const SelectTechstack: React.FC<SelectTechstackProps> = ({
  setOpenSelectTech,
  handleClose,
  setOpenProjectDetails,
  setProjectDetails,
  projectDetails
}) => {
  const handleSelect = (selectedTech: string) => {
    setProjectDetails({
      ...projectDetails,
      techStack: selectedTech
    })
    console.log('projectDetails', projectDetails)
    setOpenProjectDetails(true)
    setOpenSelectTech(false)
  }
  const createdFirstProject = useSelector(
    (state: RootState) => state.user.userProfileData?.organization?.createdFirstProject
  )
  const mode = useSelector((state: RootState) => state.user.darkMode)

  return (
    <div
      className={`p-6 md:w-[25vw] md:min-w-[25rem] max-w-[30rem]  ${
        mode == 'dark' ? 'bg-dark' : 'bg-white'
      }     rounded-xl     flex-col justify-start items-center gap-7 inline-flex`}
    >
      <div className='self-stretch justify-center items-start gap-2 inline-flex'>
        <div className='grow shrink   basis-0 px-4 flex-col justify-start items-center gap-1 inline-flex'>
          <div className='flex flex-grow  justify-center items-center '>
            <div className="text-center  dark:text-[#979BAF]  text-[1.3rem] font-semibold font-['Roboto'] leading-[33.60px]">
              Select a Tech Stack
            </div>

            {createdFirstProject ? (
              <div className='ml-auto'>
                <img
                  onClick={handleClose}
                  src={mode == 'light' ? '/images/logos/cross.png' : '/images/logos/cross_white.png'}
                  className='w-5 cursor-pointer absolute ml-[3.5rem] md:ml-[4.4rem]  mt-[-1.5rem]  '
                ></img>
              </div>
            ) : (
              <span></span>
            )}
          </div>

          <div className="text-stone-950/opacity-60 dark:text-[#979BAF] text-[0.8rem] font-normal font-['Roboto'] leading-tight">
            Choose the technology used for your next project
          </div>
        </div>
      </div>

      <div className='self-stretch flex-col dark:bg-[#24303f]   justify-start items-start gap-4 flex'>
        <button
          onClick={() => handleSelect('React')}
          className={` ${
            mode == 'dark'
              ? 'border-[#515151] active:bg-neutral-600 light:hover:bg-neutral-200'
              : 'border-zinc-500/opacity-40 active:bg-neutral-300 light:hover:bg-neutral-200 '
          }  self-stretch  p-2 dark:bg-[#24303f] rounded-[20px] shadow border dark:border-[#515151] border-zinc-500/opacity-40    justify-start items-center gap-7 inline-flex`}
        >
          <div
            className={`w-[35%] h-[5rem] ${
              mode == 'dark' ? 'bg-[#37383D]' : 'bg-neutral-100'
            }  dark:bg-[#37383D] flex justify-center items-center   rounded-xl`}
          >
            <img src='/images/logos/reactFade.png' className='w-[50%] max-w-[5rem]  rounded-xl ' />
          </div>
          <div className='flex-col justify-center items-start gap-1 inline-flex'>
            <div className="text-center dark:bg-[#24303f] text-[1.2rem] font-medium font-['Roboto'] leading-relaxed">
              React
            </div>
            <div className="text-center dark:text-[#979BAF] text-stone-950/opacity-60 text-[0.8rem] font-normal font-['Roboto'] leading-tight">
              Best for Web apps
            </div>
          </div>
        </button>

        <button
          onClick={() => handleSelect('Native')}
          className={` ${
            mode == 'dark'
              ? 'border-[#515151] active:bg-neutral-600 light:hover:bg-neutral-200'
              : 'border-zinc-500/opacity-40 active:bg-neutral-300 light:hover:bg-neutral-200 '
          }  self-stretch  p-2 dark:bg-[#24303f] rounded-[20px] shadow border dark:border-[#515151] border-zinc-500/opacity-40    justify-start items-center gap-7 inline-flex`}
        >
          <div
            className={`w-[35%] h-[5rem] ${
              mode == 'dark' ? 'bg-[#37383D]' : 'bg-neutral-100'
            }  dark:bg-[#37383D] flex justify-center items-center   rounded-xl`}
          >
            <img src='/images/logos/reactFade.png' className='w-[50%] dark:bg-[#37383D]   rounded-xl ' />
          </div>
          <div className='flex-col   justify-center items-start gap-1 inline-flex'>
            <div className="text-center dark:bg-[#24303f] text-[1.2rem] font-medium font-['Roboto'] leading-relaxed">
              React Native
            </div>
            <div className="text-center dark:text-[#979BAF]  text-stone-950/opacity-60 text-[0.8rem] font-normal font-['Roboto'] leading-tight">
              Best for Native apps
            </div>
          </div>
        </button>

        <button
          onClick={() => handleSelect('Web')}
          className={` ${
            mode == 'dark'
              ? 'border-[#515151] active:bg-neutral-600 light:hover:bg-neutral-200'
              : 'border-zinc-500/opacity-40 active:bg-neutral-300 light:hover:bg-neutral-200 '
          }  self-stretch  p-2 dark:bg-[#24303f] rounded-[20px] shadow border dark:border-[#515151] border-zinc-500/opacity-40    justify-start items-center gap-7 inline-flex`}
        >
          <div
            className={`w-[35%] h-[5rem] ${
              mode == 'dark' ? 'bg-[#37383D]' : 'bg-neutral-100'
            }  dark:bg-[#37383D] flex justify-center items-center   rounded-xl`}
          >
            <img src='/images/logos/globe.png' className='w-[50%]    rounded-xl ' />
          </div>
          <div className='flex-col justify-center items-start gap-1 inline-flex'>
            <div className="text-center dark:bg-[#24303f] text-[1.2rem] font-medium font-['Roboto'] leading-relaxed">
              Web SDK
            </div>
            <div className="text-center dark:text-[#979BAF] text-stone-950/opacity-60 text-[0.8rem] font-normal font-['Roboto'] leading-tight">
              Best for Web apps
            </div>
          </div>
        </button>
      </div>
    </div>
  )
}
export default SelectTechstack

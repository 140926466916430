'use client'

import Link from 'next/link'
import { MoreHorizontal, LogOut } from 'lucide-react'
import { usePathname } from 'next/navigation'

import { shadcn as cn, shadcn } from 'src/@core/utils/helper-functions'
import { Button } from '../../components/shadcn/button'

import { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider } from '../../components/shadcn/tooltip'

import { CollapseMenuButton } from './collapse-menu-button'
import { getMenuList } from 'src/utils/menu-list'

interface MenuProps {
  isOpen: boolean | undefined
  setOpen?: (open: boolean) => void
}

function MenuMobile({ isOpen, setOpen }: MenuProps) {
  const pathname = usePathname()
  const menuList = getMenuList(pathname)
  const onClick = () => {
    console.log('log out')
  }

  const hideSheet = () => {
    setOpen && setOpen(false)
  }
  return (
    <nav className='mt-4 h-full w-full dark:bg-[#24303F]'>
      <ul className='flex flex-col min-h-[calc(60vh-48px-36px-16px-32px)] lg:min-h-[calc(80vh-32px-40px-32px)] items-start space-y-1 px-2 '>
        {menuList.map(({ groupLabel, menus }, index) => (
          <li className={cn('w-full', groupLabel ? 'pt-5' : '')} key={index}>
            {(isOpen && groupLabel) || isOpen === undefined ? (
              <p className='text-sm font-medium text-muted-foreground px-4 pb-2 max-w-[248px] truncate'>{groupLabel}</p>
            ) : !isOpen && isOpen !== undefined && groupLabel ? (
              <TooltipProvider>
                <Tooltip delayDuration={100}>
                  <TooltipTrigger className='w-full'>
                    <div className='w-full flex justify-center items-center'>
                      <MoreHorizontal className='h-5 w-5' />
                    </div>
                  </TooltipTrigger>
                  <TooltipContent side='right'>
                    <p>{groupLabel}</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            ) : (
              <p className='pb-2'></p>
            )}
            {menus.map(({ href, label, icon: Icon, active, submenus }, index) =>
              submenus.length === 0 ? (
                <div className='w-full' key={index}>
                  <TooltipProvider disableHoverableContent>
                    <Tooltip delayDuration={100}>
                      <TooltipTrigger asChild>
                        <Button
                          className={shadcn('w-full justify-start h-10 mb-1', active && 'bg-gray-200/40')}
                          asChild
                          onClick={hideSheet}
                        >
                          <Link href={href}>
                            <span className={cn(isOpen === false ? '' : 'mr-4 text-white')}>
                              <Icon size={18} />
                            </span>
                            <p
                              className={cn(
                                'max-w-[200px] truncate text-white',
                                isOpen === false ? '-translate-x-96 opacity-0' : 'translate-x-0 opacity-100'
                              )}
                            >
                              {label}
                            </p>
                          </Link>
                        </Button>
                      </TooltipTrigger>
                      {isOpen === false && (
                        <TooltipContent side='right' className='bg-black text-white'>
                          {label}
                        </TooltipContent>
                      )}
                    </Tooltip>
                  </TooltipProvider>
                </div>
              ) : (
                <div className='w-full text-white dark:bg-[#23303F]' key={index}>
                  <CollapseMenuButton icon={Icon} label={label} active={active} submenus={submenus} isOpen={isOpen} />
                </div>
              )
            )}
          </li>
        ))}
      </ul>
    </nav>
  )
}
export default MenuMobile

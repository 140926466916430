import React, { useEffect, useState } from 'react'
import { useAuth } from 'src/hooks/useAuth'
import { useDispatch } from 'react-redux'
import { updateSelectedClientId } from 'src/redux/user/userSlice'
import { OrganizationType } from 'src/types/apps/userTypes'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../components/shadcn/select'
import { Skeleton } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from 'src/redux/store'

const ClientsDropdownMenu = () => {
  const auth = useAuth()
  const [organizationData, setOrganizationData] = useState<OrganizationType[]>([])
  const [loading, setLoading] = useState(true)
  const [currentClient, setCurrentClient] = useState('all')
  const dispatch = useDispatch()
   const userData = useSelector((state: RootState) => state.user.userProfileData)
  const isRestrictedUser = userData?.email === 'user@threely.io'

  const fetchData = async () => {
    try {
      const data = await auth.getClients()
      if (data.success) {
        setOrganizationData(data.organizations)
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleClientChange = (value: string) => {
    setCurrentClient(value)
    dispatch(updateSelectedClientId(value))
  }

  if (loading) {
    return (
      <div className='mt-4 mb-4'>
        <Skeleton className='h-10 w-60' />
      </div>
    )
  }

  return (
    <div className='mt-4 mb-4'>
       <Select 
      value={currentClient} 
      onValueChange={handleClientChange}
      defaultValue={isRestrictedUser ? 'all' : undefined}
    >
      <SelectTrigger className='w-60'>
        <SelectValue placeholder='Select a client' />
      </SelectTrigger>
      <SelectContent className='max-h-80'>
        {isRestrictedUser ? (
          <SelectItem value='all'>All Clients</SelectItem>
        ) : (
          <>
            <SelectItem value='all'>All Clients</SelectItem>
            {organizationData.filter(organization => organization?.isApproved === "approved").map(organization => (
    <SelectItem
      key={organization.id}
      value={organization.id}
    >
      {organization?.organizationDetails?.name || organization?.email}
    </SelectItem>
  ))}
          </>
        )}
      </SelectContent>
    </Select>
    </div>
  )
}

export default ClientsDropdownMenu

import { AxiosInstance } from 'axios';
import { useEffect, useState, createContext, ReactNode, useRef } from 'react';
import { useSelector } from 'react-redux';
import { createAxiosInstance } from 'src/configs/axios';

const defaultProvider = {
  axiosInstance: null
};

type AxiosContextType = {
  axiosInstance: AxiosInstance | null
  axiosState: AxiosInstance | null
};

const AxiosContext = createContext<AxiosContextType>({ axiosInstance: null, axiosState: null });

type Props = {
  children: ReactNode
};

const AxiosProvider: React.FC<Props> = ({ children }) => {
  const selectedClientId = useSelector((state: any) => state.user.selectedClientId);
  const instance = createAxiosInstance(selectedClientId);
  const axiosRef = useRef<AxiosInstance>(instance);
  const [axiosInstance, setAxiosInstance] = useState<AxiosInstance>(instance);

  useEffect(() => {
    // Create and set the Axios instance
    const newAxiosInstance = createAxiosInstance(selectedClientId);
    axiosRef.current = newAxiosInstance;
    setAxiosInstance(newAxiosInstance);

    // Cleanup the effect when the component unmounts or selectedClientId changes
    // return () => {
    //   axiosRef.current = null;
    // };
  }, [selectedClientId]);

  return (
    <AxiosContext.Provider value={{ axiosInstance: axiosRef.current, axiosState: axiosInstance }}>
      {children}
    </AxiosContext.Provider>
  );
};

export { AxiosProvider, AxiosContext };

import { ChevronLeft } from 'lucide-react'

import { shadcn as cn } from 'src/@core/utils/helper-functions'
import { Button } from '../../components/shadcn/button'

interface SidebarToggleProps {
  isOpen: boolean | undefined
  setIsOpen?: () => void
}

export function SidebarToggle({ isOpen, setIsOpen }: SidebarToggleProps) {
  return (
    <div className='invisible lg:visible absolute top-[12px] right-[6px] z-50'>
      <Button onClick={() => setIsOpen?.()} className='rounded-md w-8 h-8' variant='outline' size='icon'>
        <ChevronLeft
          className={cn(
            'h-4 w-4 transition-transform ease-in-out duration-700',
            isOpen === false ? 'rotate-180' : 'rotate-0'
          )}
        />
      </Button>
    </div>
  )
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { adminData } from "../initialState/adminData";
import { AxiosInstance } from "axios";
import { organizationStatus } from "src/types/apps/adminTypes";

export const getPendingClients = createAsyncThunk('admin/getPendingClients', async ({ axiosInstance, organizationStatus }: { axiosInstance: any, organizationStatus: organizationStatus }) => {
    const apiUrl = `/admin/getPendingOrganizations?organizationStatus=${organizationStatus}`;
    const response = await axiosInstance.get(apiUrl);
    return response.data.data
})

export const approveClient = createAsyncThunk('admin/approveClient', async (params: { axiosInstance: any, orgId: string, operation: string }) => {
    const apiUrl = '/admin/changeOrganizationApprovedStatus';
    const response = await params.axiosInstance.post(apiUrl, { id: params.orgId, operation: params.operation });
    return response.data
})

// export const getApprovedClients  = createAsyncThunk('admin/getAllClientsDetails', async (params: { axiosInstance: any, orgId: string, operation: string }) => {
//     const apiUrl = '/admin/changeOrganizationApprovedStatus';
//     const response = await params.axiosInstance.get(apiUrl, { id: params.orgId, operation: params.operation });
//     return response.data
// })

export const adminSlice = createSlice({
    name: 'admin',
    initialState: adminData,
    reducers: {
        setUser(state, action) {
            state.pendingClients = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPendingClients.fulfilled, (state, action) => {
                state.pendingClients.data = action.payload;
                state.pendingClients.loading = false;
            })
            .addCase(getPendingClients.rejected, (state, action) => {
                state.pendingClients.loading = false;
                state.pendingClients.error = action.error;
                console.error('Error fetching pending clients:', action.error);
            })
            .addCase(getPendingClients.pending, (state, action) => {
                state.pendingClients.loading = true; 
                state.pendingClients.error = null;
            })
            .addCase(approveClient.pending, (state, action) => {
                state.pendingClients.loading = true;
                state.pendingClients.error = null;
            })
            .addCase(approveClient.fulfilled, (state, action) => {
                state.pendingClients.loading = false;
                state.pendingClients.data = state.pendingClients.data.filter(client => client.id !== action.payload.data.id);
            })
            .addCase(approveClient.rejected, (state, action) => {
                state.pendingClients.loading = false;
                state.pendingClients.error = action.error;
                console.error('Error approving client:', action.error);
            })
    }
})

export const { setUser } = adminSlice.actions;

export default adminSlice.reducer;
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { userProfileDetails } from 'src/context/types'
import { ProjectType, UserDataType } from 'src/types/apps/userTypes'

interface reduxState {
  userProfileData: UserDataType | null // Assuming UserProfileData is an interface representing user profile data
  selectedTriaNames: string[] // Explicitly typed as an array of strings
  selectedClientId: string
  selectedProject: ProjectType | null
  selectedProjectId: string
  selectedEnviornment: 'live' | 'sandbox'
  allProjects: ProjectType[]
  darkMode: string
  allClients: any[]
}
interface Project {
  _id: string
  clientId: string
  projectId: string
  projectName: string
  apiKey: string
  // Add other project fields as needed
}
interface theme {
  darkMode: string
}

const getInitialEnvironment = (): 'live' | 'sandbox' => {
  if (typeof window !== 'undefined') {
    const storedEnvironment = localStorage.getItem('ENVIRONMENT');
    if (storedEnvironment === 'live' || storedEnvironment === 'sandbox') {
      return storedEnvironment;
    }
  }
  return 'sandbox'; // Default to 'sandbox' if stored value is invalid or in a non-browser environment
};
const initialState: reduxState = {
  userProfileData: null,
  selectedTriaNames: [],
  selectedClientId: 'all',
  selectedProject: null,
  selectedEnviornment: getInitialEnvironment(),
  selectedProjectId: '',
  allProjects: [],
  darkMode: '',
  allClients: []
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUserProfileData: (state, action) => {
      state.userProfileData = action.payload
    },
    updateOrganizationDetails: (state, action) => {
      if (state.userProfileData === null) {
        console.error("There seems to be some problem with your network")
        return;
      }
      state.userProfileData.organization = action.payload;
      // const payload = action.payload;
      // delete payload.email;
      // const email = action.payload.email;
      // if (state.userProfileData === null) {
      //   console.error("There seems to be some problem with your network")
      //   return;
      // }
      // state.userProfileData.organization.email = email;
      // state.userProfileData.organization.organizationDetails = { ...(state.userProfileData.organization.organizationDetails), ...payload }
    },
    updateSelectedTriaNames: (state, action) => {
      console.log('action', action.payload)
      const triaNames: string[] = action.payload.triaNames
      const deselectAllRows: boolean = action.payload.deselectAllRows
      if (deselectAllRows) {
        state.selectedTriaNames = []
        return
      }
      if (triaNames.length < state.selectedTriaNames.length) {
        state.selectedTriaNames = state.selectedTriaNames.filter(selectedId => triaNames.includes(selectedId))
      } else {
        //@ts-ignore
        state.selectedTriaNames = [...new Set([...state.selectedTriaNames, ...triaNames])]
        console.log('redux update', state.selectedTriaNames)
      }
    },
    updateSelectedClientId: (state, action) => {
      state.selectedClientId = action.payload
    },
    updateSelectedProjectDetails: (state, action) => {
      state.selectedProjectId = action.payload
    },
    updateAllProjects: (state, action) => {
      console.log('updateAllProjects', action.payload)
      state.allProjects = action.payload
    },
    addeNewProject: (state, action) => {
      const newProject = action.payload;
      const index = state.allProjects.findIndex(project => project.id === newProject.id)
      if (index !== -1) {
        state.allProjects[index] = newProject
        state.selectedProjectId = newProject.id
        return  // no need to add new project again, as it already exists in the array.  So return early.  This optimizes the function by avoiding unnecessary array operations.  If the project does not already exist, it will be added at the end of the array.  This will be more efficient.  However, if the project exists, the existing project will be updated with the new values.  This is the desired behavior.  If you want to add a new project and keep it separate from existing ones, remove the return statement.  This is the original behavior.  But, if you want to replace an existing project with a new one, uncomment the return statement.  But, this will remove the existing project and replace it with the new one.  So, the new project will replace the old one in the array.  This is the desired behavior.
      }
      state.allProjects.push(newProject)
    },
    deleteProject: (state, action) => {
      const projectIdToDelete = action.payload;
      const index = state.allProjects.findIndex(project => project.id === projectIdToDelete)
      if (index !== -1) {
        state.allProjects.splice(index, 1)
        if (state.selectedProject?.id === projectIdToDelete) {
          state.selectedProject = state.allProjects[0] || null
          state.selectedProjectId = 'all'  // set the selected project to 'all' if the deleted project was the selected project.  This ensures that the 'all' project is selected when the user navigates to the project list.  If you want to keep the deleted project selected, remove the 'all' project selection.  This is the original behavior.  But, if you want to replace the deleted project with a new one, uncomment the line below.  But, this will remove the deleted project and replace it with the new one.  So, the new project will replace the old one in the array.  This is the desired behavior.  But, this will not affect the selection of the deleted project.  So, the deleted project will still be selected when the user navigates to the project list.  This is the original behavior.  But, if you want to keep the deleted project selected, remove the line below.
        }
      }
    },
    updateSelectedProject: (state, action: PayloadAction<ProjectType | null>) => {
      console.log("action.payload", action.payload);
      if(action.payload?.id=='all'){
        state.selectedProjectId = '';
      }
      state.selectedProject = action.payload
    },
    updateSelectedEnviornment: (state, action: PayloadAction<'live' | 'sandbox'>) => {
      console.log("updaing enviroment", action.payload, state.selectedProject?.[action.payload])
      state.selectedEnviornment = action.payload
      //@ts-ignore
      state.selectedProjectId = state.selectedProject?.[action.payload];
    },
    updateSandboxSteps: (state, action) => {
      if (state.selectedProject !== null) {
        state.selectedProject.sandboxSteps = action.payload
        const id = state.selectedProject.id
        const index = state.allProjects.findIndex(project => project.id === id)
        state.allProjects[index].sandboxSteps = action.payload
      }

    },
    updateAllClients: (state, action) => {
      state.allClients = action.payload
    },
    updateClientStatus: (state, action: PayloadAction<{ clientId: string, isActive: boolean }>) => {
      const { clientId, isActive } = action.payload
      const client = state.allClients.find(client => client.clientId === clientId)
      if (client) {
        client.isActive = isActive
      }
    },
    changeTheme: (state, action) => {
      state.darkMode = action.payload
    },
    updateOrganizationPlan: (state, action) => {
      if (state.userProfileData === null)
        return;
      state.userProfileData.organization.plan = action.payload
      state.userProfileData.organization.planId = action.payload.id
    }

  }
})

export const {
  updateUserProfileData,
  updateSelectedTriaNames,
  updateSelectedClientId,
  updateOrganizationPlan,
  updateAllProjects,
  updateSelectedProjectDetails,
  addeNewProject,
  updateOrganizationDetails,
  updateSelectedProject,
  updateSelectedEnviornment,
  deleteProject,
  updateSandboxSteps,
  changeTheme,
  updateAllClients,
  updateClientStatus
} = userSlice.actions

export default userSlice.reducer

// ** React Imports
import { useState } from 'react'

// ** MUI Imports
import Fab from '@mui/material/Fab'
import { styled } from '@mui/material/styles'
import Box, { BoxProps } from '@mui/material/Box'

// ** Icon Imports
import Icon from 'src/@core/components/icon'

// ** Theme Config Import
import themeConfig from 'src/configs/themeConfig'

// ** Type Import
import { LayoutProps } from 'src/@core/layouts/types'

// ** Components
import AppBar from './components/vertical/appBar'
import Customizer from 'src/@core/components/customizer'
import Navigation from './components/vertical/navigation'
import Footer from './components/shared-components/footer'
import ScrollToTop from 'src/@core/components/scroll-to-top'
import { Card, CardContent } from '@mui/material'
import UserDropdown from 'src/@core/layouts/components/shared-components/UserDropdown'
import Sidebar from 'src/components/new-sidebar/sidebar'
import { Navbar } from 'src/components/new-sidebar/layout-navbar'
import { shadcn } from '../utils/helper-functions'
import { useStore } from 'src/hooks/use-store'
import { useSidebarToggle } from 'src/hooks/use-sidebar-toggle'

const VerticalLayoutWrapper = styled('div')({
  height: '100%',
  display: 'flex'
})

const MainContentWrapper = styled(Box)<BoxProps>({
  flexGrow: 1,
  minWidth: 0,
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column'
})

const ContentWrapper = styled('main')(({ theme }) => ({
  flexGrow: 1,
  width: '100%',
  padding: theme.spacing(6),
  transition: 'padding .25s ease-in-out',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  }
}))

const VerticalLayout = (props: LayoutProps) => {
  // ** Props
  const { hidden, settings, children, scrollToTop, footerProps, contentHeightFixed, verticalLayoutProps } = props

  // ** Vars
  const { skin, navHidden, contentWidth } = settings
  const navigationBorderWidth = skin === 'bordered' ? 1 : 0
  const { navigationSize, disableCustomizer, collapsedNavigationSize } = themeConfig
  const navWidth = navigationSize
  const collapsedNavWidth = collapsedNavigationSize

  // ** States
  const [navVisible, setNavVisible] = useState<boolean>(false)

  // ** Toggle Functions
  const toggleNavVisibility = () => setNavVisible(!navVisible)
  const sidebar = useStore(useSidebarToggle, state => state)
  if (!sidebar) return null
  return (
    <>
      {/* <VerticalLayoutWrapper className='layout-wrapper'> */}
      {/* Navigation Menu
        {navHidden && !(navHidden && settings.lastLayout === 'horizontal') ? null : (
          <Navigation
            navWidth={navWidth}
            navVisible={navVisible}
            setNavVisible={setNavVisible}
            collapsedNavWidth={collapsedNavWidth}
            toggleNavVisibility={toggleNavVisibility}
            navigationBorderWidth={navigationBorderWidth}
            navMenuContent={verticalLayoutProps.navMenu.content}
            navMenuBranding={verticalLayoutProps.navMenu.branding}
            menuLockedIcon={verticalLayoutProps.navMenu.lockedIcon}
            verticalNavItems={verticalLayoutProps.navMenu.navItems}
            navMenuProps={verticalLayoutProps.navMenu.componentProps}
            menuUnlockedIcon={verticalLayoutProps.navMenu.unlockedIcon}
            afterNavMenuContent={verticalLayoutProps.navMenu.afterContent}
            beforeNavMenuContent={verticalLayoutProps.navMenu.beforeContent}
            {...props}
          />
        )} */}
      <Navbar className='fixed top-0 left-0 right-0' />
      <Sidebar />
      <main
        className={shadcn(
          'min-h-[calc(100vh_-_56px)] dark:bg-[#24303F] transition-[margin-left] ease-in-out duration-300 mt-14 md:mt-24 bg-white px-8',
          sidebar?.isOpen === false ? 'lg:ml-[90px]' : 'lg:ml-[260px]'
        )}
      >
        {/* <MainContentWrapper
            className='layout-content-wrapper'
            sx={{ ...(contentHeightFixed && { maxHeight: '100vh' }) }}
          > */}
        {/* AppBar Component */}
        {/* <AppBar
              toggleNavVisibility={toggleNavVisibility}
              appBarContent={verticalLayoutProps.appBar?.content}
              appBarProps={verticalLayoutProps.appBar?.componentProps}
              {...props}
            /> */}

        {/* <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', paddingTop: 3, marginRight: 3 }}>
            <UserDropdown settings={settings} />
          </Box> */}

        {/* Content */}
        {/* <ContentWrapper
          className='layout-page-content'
          sx={{
            ...(contentHeightFixed && {
              overflow: 'hidden',
              '& > :first-of-type': { height: '100%' }
            }),
            ...(contentWidth === 'boxed' && {
              mx: 'auto',
              '@media (min-width:1440px)': { maxWidth: 1440 },
              '@media (min-width:1200px)': { maxWidth: '100%' }
            })
          }}
        > */}
        {children}
        {/* </ContentWrapper> */}

        {/* Footer Component */}
        <Footer footerStyles={footerProps?.sx} footerContent={footerProps?.content} {...props} />
        {/* </MainContentWrapper> */}
      </main>
      {/* </VerticalLayoutWrapper> */}

      {/* Customizer */}
      {disableCustomizer || hidden ? null : <Customizer />}

      {/* Scroll to top button */}
      {scrollToTop ? (
        scrollToTop(props)
      ) : (
        <ScrollToTop className='mui-fixed'>
          <Fab color='primary' size='small' aria-label='scroll back to top'>
            <Icon icon='tabler:arrow-up' />
          </Fab>
        </ScrollToTop>
      )}
    </>
  )
}

export default VerticalLayout

// ** Type import
import { useSelector } from 'react-redux';
import { VerticalNavItemsType } from 'src/@core/layouts/types'

const navigation = (): VerticalNavItemsType => {
  const userData = useSelector((state: any) => state.user.userProfileData);
  const mode = useSelector((state: any) => state.user.darkMode);
  const userRole = userData?.role;

  return [
    {
      title: 'Overview',
      icon: 'tabler:3d-cube-sphere',
      badgeColor: 'error',
      path: '/dashboards/overview'
    },
    {
      title: 'Home',
      icon: 'tabler:smart-home',
      badgeColor: 'error',
      path: '/dashboards/analytics'
    },
    {
      title: 'User Details',
      icon: 'tabler:user',
      badgeColor: 'error',
      path: '/dashboards/userDetails'
    },
    {
      title: 'User Activity',
      icon: 'tabler:align-box-bottom-center',
      badgeColor: 'error',
      path: '/dashboards/userActivities'
    },
    {
      title: 'Dropoff',
      icon: 'tabler:forms',
      admin:true,
      children: [
        {
          icon:'tabler:device-analytics',
          title: 'analytics',
          path: '/dashboards/dropoff/analytics'
        },
        {
          icon:'tabler:brand-tabler',
          title: 'User Details',
          path: '/dashboards/dropoff/userDetails'
        }
      ]
    },
    {
      title: 'Transactions',
      icon: 'tabler:activity-heartbeat',
      badgeColor: 'error',
      path: '/dashboards/transactionDetails'
    },
    {
      title: 'Customization',
      icon: 'tabler:brand-tabler',
      badgeColor: 'error',
      path: '/custom-ui'
    },
    {
      title: 'Manage Projects',
      icon: 'tabler:briefcase',
      badgeColor: 'error',
      path: '/projects'
    },  
    {
      title: 'All Clients',
      icon: 'tabler:users', // Changed icon here
      badgeColor: 'error',
      path: '/dashboards/allClients',
      admin:true

    },
    
    {
      title: 'Settings',
      icon: 'tabler:settings',
      badgeColor: 'error',
      path: '/pages/account-settings/account'
    },
    {
      title: 'Dark mode',
      icon: mode=='dark'?'tabler:sun':'tabler:moon',
      badgeColor: 'error',
     // path: '/pages/account-settings/account'
    },
    


    // Add more  items as needed
  ]
 
}



export default navigation

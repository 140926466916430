import {
  LayoutGrid,
  Home,
  User,
  Activity,
  FileSpreadsheet,
  Heart,
  Palette,
  Briefcase,
  Users,
  Settings,
  Sun,
  Moon,
  Box,
  LucideIcon,
  CircleHelp,
  FileText
} from 'lucide-react'
import { useSelector } from 'react-redux'
import { RootState } from 'src/redux/store'

type Submenu = {
  href: string
  label: string
  active: boolean
  icon?: LucideIcon
  resource?: string
}

type Menu = {
  href: string
  label: string
  active: boolean
  icon: LucideIcon
  submenus: Submenu[]
  admin?: boolean
  resource?: string
}

type Group = {
  groupLabel: string
  menus: Menu[]
}

type Permission = {
  resource: string
  actions: string[]
}

// interface RootState {
//   user: {
//     darkMode: boolean
//     userProfileData: {
//       role?: {
//         name?: string
//         permissions?: Permission[]
//       }     
//     } | null
//   }
// }

export function getMenuList(pathname: string): Group[] {
  const mode = useSelector((state: RootState) => state.user.darkMode)
  const userData = useSelector((state: RootState) => state.user.userProfileData)

  const userRole = userData?.role?.name ?? '';
  const permissions = userData?.role?.permissions ?? []
   const userEmail = userData?.email ?? ''

  console.log("userRole-->",userRole,permissions,userEmail);
  // Helper function to check if user has read permission for a resource
  const hasReadPermission = (resource: string): boolean => {
    const permission = permissions.find(p => p.resource === resource)
    return permission?.actions.includes('read') ?? false
  }

  // Define base menu items with resource mapping
  const baseMenus: Menu[] = [
    {
      href: '/dashboards/overview',
      label: 'Overview',
      active: pathname?.includes('/dashboards/overview') ?? false,
      icon: Box,
      submenus: [],
      // resource: 'overview' 
    },
    {
      href: '/dashboards/analytics',
      label: 'Home',
      active: pathname?.includes('/dashboards/analytics') ?? false,
      icon: Home,
      submenus: [],
      // resource: 'analytics'
    },
    {
      href: '/dashboards/userDetails',
      label: 'User Details',
      active: pathname?.includes('/dashboards/userDetails') ?? false,
      icon: User,
      submenus: [],
      // resource: 'userDetails'
    },
    {
      href: '/dashboards/userActivities',
      label: 'User Activity',
      active: pathname?.includes('/dashboards/userActivities') ?? false,
      icon: Activity,
      submenus: [],
      // resource: 'userActivities'
    },
    {
      href: '/dashboards/transactionDetails',
      label: 'Transactions',
      active: pathname?.includes('/dashboards/transactionDetails') ?? false,
      icon: Heart,
      submenus: [],
      // resource: 'transactionDetails'
    },
    {
      href: '/custom-ui',
      label: 'Customization',
      active: pathname?.includes('/custom-ui') ?? false,
      icon: Palette,
      submenus: [],
      // resource: 'customization'
    },
    {
      href: '/projects',
      label: 'Manage Projects',
      active: pathname?.includes('/projects') ?? false,
      icon: Briefcase,
      submenus: [],
      // resource: 'project'
    },
    {
      href: '/pages/account-settings/account',
      label: 'Settings',
      active: pathname?.includes('/pages/account-settings/account') ?? false,
      icon: Settings,
      submenus: [],
      // resource: 'account'
    }
  ]

  const adminMenus: Menu[] = [
    {
      href: '/dashboards/dropoff',
      label: 'Dropoff',
      active: pathname?.includes('/dashboards/dropoff') ?? false,
      icon: FileSpreadsheet,
      admin: true,
      // resource: 'organisation',
      submenus: [
        {
          href: '/dashboards/dropoff/analytics',
          label: 'Analytics',
          active: pathname?.includes('/dashboards/dropoff/analytics') ?? false,
          icon: Activity,
          // resource: 'dropoff'
        },
        {
          href: '/dashboards/dropoff/userDetails',
          label: 'User Details',
          active: pathname?.includes('/dashboards/dropoff/userDetails') ?? false,
          icon: User,
          // resource: 'dropoff'
        }
      ]
    },
    {
      href: '/dashboards/allClients',
      label: 'All Clients',
      active: pathname?.includes('/dashboards/allClients') ?? false,
      icon: Users,
      admin: true,
      // resource: 'allClients',
      submenus: []
    }
  ]

  // Filter menus based on permissions
  const filterMenusByPermission = (menus: Menu[]): Menu[] => {
    return menus.filter(menu => {
      // For user@threely.io, only show Home menu
      if (userEmail === 'user@threely.io') {
        return menu.label === 'Home'
      }
  
      // For all other users, apply normal permission checks
      if (menu.resource && !hasReadPermission(menu.resource)) {
        return false
      }
  
      // Filter submenus if they exist
      if (menu.submenus.length > 0) {
        menu.submenus = menu.submenus.filter(
          submenu => !submenu.resource || hasReadPermission(submenu.resource)
        )
      }
  
      return true
    })
  }

  // Combine and filter menus based on user role and permissions
  const finalMenus = userRole === 'triaadmin'
    ? filterMenusByPermission([...baseMenus.slice(0, 4), ...adminMenus, ...baseMenus.slice(4)])
    : filterMenusByPermission(baseMenus)

  return [{
    groupLabel: '',
    menus: finalMenus
  }]
}

export default getMenuList
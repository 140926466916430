'use client'
import { shadcn as cn } from 'src/@core/utils/helper-functions'
import { SheetMenu } from './sheet-menu'
import UserDropdown from 'src/@core/layouts/components/shared-components/UserDropdown'
import WithPermission from 'src/pages/components/hoc/WithPermission'
import { Plus } from 'lucide-react'
import ClientsDropdownMenu from 'src/views/client/clientsDropDownMenu'
import { useSelector } from 'react-redux'
import { RootState } from 'src/redux/store'
import {
  Select,
  SelectContent,
  SelectTrigger,
  SelectItem,
  SelectValue,
  SelectGroup
} from '../../components/shadcn/select'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../../components/shadcn/tooltip'

import { useEffect, useState } from 'react'
import { ProjectDetailsForm } from 'src/types'
import { useAuth } from 'src/hooks/useAuth'
import { useTheme } from '@emotion/react'
import { useAxios } from 'src/hooks/useAxios'
import { useDispatch } from 'react-redux'
import {
  changeTheme,
  updateAllProjects,
  updateSelectedEnviornment,
  updateSelectedProject,
  updateSelectedProjectDetails
} from 'src/redux/user/userSlice'
import toast from 'react-hot-toast'
import SelectTechstack from 'src/views/overview/SelectTechstack'
import ProjectDetails from 'src/views/overview/ProjectDetails'
import { Dialog } from '@mui/material'

interface NavbarProps {
  className?: string
}

const defaultProjectDetails = {
  name: '',
  imageUrl: '',
  techStack: undefined,
  title: 'Create New Project',
  button: 'Create Project',
  whitelistedOrigin: []
}

export function Navbar({ className }: NavbarProps) {
  // ** Hook
  const auth = useAuth()
  const theme = useTheme()
  const environment = useSelector((state: RootState) => state.user.selectedEnviornment)
  const axiosInstance = useAxios()
  const dispatch = useDispatch()

  const mode = 'light' // Define the mode variable
  const userData = useSelector((state: any) => state.user.userProfileData)
  const allProject = useSelector((state: RootState) => state.user.allProjects)
  const allOption = {
    id: 'all',
    name: 'All Projects',
    imageUrl: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    live: '',
    sandbox: '',
    techStack: undefined,
    whitelistedOrigin: [],
    organizationId: ''
  }

  const allProjects = [allOption, ...allProject]
  const selectedProject = useSelector((state: RootState) => state.user.selectedProject)
  const selectedProjectId = useSelector((state: RootState) => state.user.selectedProjectId)
  const [openProjectSelect, setOpenProjectSelect] = useState(false)
  const [openSelectTech, setOpenSelectTech] = useState(false)
  const [openProjectDetails, setOpenProjectDetails] = useState(false)
  const [variant, setVariant] = useState('')
  const [projectDetails, setProjectDetails] = useState<ProjectDetailsForm>(defaultProjectDetails)

  useEffect(() => {
    if (openProjectDetails === false) {
      setProjectDetails(defaultProjectDetails)
    }
  }, [openProjectDetails])

  useEffect(() => {
    if (userData?.role === undefined) return

    if (userData?.organization?.createdFirstProject !== true) {
      setOpenSelectTech(true)
      setVariant('FirstProject')
    } else {
      setOpenSelectTech(false)
      setVariant('')
    }
  }, [userData])

  const handleEnvironmentChange = (value: 'live' | 'sandbox') => {
    console.log("value--->",value);
    localStorage.setItem("ENVIRONMENT", value);
    dispatch(updateSelectedEnviornment(value))
  }

  const handleProjectChange = (projectId: string) => {
    const project = allProjects.find(p => p.id === projectId)
    if (!project) return

    dispatch(
      updateSelectedProject({
        ...project,
        createdAt: project.createdAt ? new Date(project.createdAt) : new Date(),
        updatedAt: project.updatedAt ? new Date(project.updatedAt) : new Date(),
        techStack: project.techStack as 'React' | 'Web' | 'Native' | undefined
      })
    )
    //@ts-ignore
    const environmentId = project[environment]
    if (environmentId) {
      dispatch(updateSelectedProjectDetails(environmentId))
    }
  }

  const fetchProjectData = async () => {
    try {
      const response = await auth.getProjectsData()
      if (response.success) {
        dispatch(updateAllProjects(response.response))
        if (!selectedProjectId) {
          dispatch(updateSelectedProject(allOption))
        }
      }
    } catch (error) {
      toast(`${error}`)
    }
  }

  useEffect(() => {
    fetchProjectData()
  }, [axiosInstance])

  const handleAddProject = () => {
    setOpenProjectSelect(false)
    setOpenSelectTech(true)
  }

  return (
    <header
      className={cn(
        'h-14 md:h-20  flex items-center justify-between md:justify-end sticky top-0 w-full bg-white dark:bg-[#24303F] z-50',
        className
      )}
    >
      <div className='mx-4 sm:mx-8 flex h-14 items-center w-full justify-between'>
        <div className='flex items-center space-x-4 lg:space-x-0'>
          <SheetMenu />
        </div>
        <div className='flex  items-center gap-3 md:gap-5'>
          {userData?.role && (
            <>
              {userData?.role?.name == 'triaadmin' ? (
                <ClientsDropdownMenu />
              ) : (
                <>
                  {allProjects.length > 0 ? (
                    <div className='flex items-center gap-4 md:gap-6'>
                      <Select
                        value={selectedProject?.id}
                        onValueChange={handleProjectChange}
                        open={openProjectSelect}
                        onOpenChange={setOpenProjectSelect}
                      >
                        <SelectTrigger className='w-[120px] md:w-[200px] overflow-hidden'>
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger>
                                <SelectValue placeholder='Select Project' />
                              </TooltipTrigger>
                              <TooltipContent className='z-50 md:hidden ' side='bottom' sideOffset={20}>
                                {selectedProject?.name}
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        </SelectTrigger>
                        <SelectContent className='bg-white dark:bg-black'>
                          <SelectGroup className='dark:bg-[#24303F]'>
                            {allProjects.map(project => (
                              <>
                                <SelectItem
                                  key={project.id}
                                  value={project.id}
                                  className='cursor-pointer hover:bg-gray-300/40 dark:text-white'
                                >
                                  {project.name}
                                </SelectItem>
                              </>
                            ))}
                            <WithPermission resource='project' action='create'>
                              <div
                                className='flex items-center gap-2 p-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-800'
                                onClick={handleAddProject}
                              >
                                <Plus className='w-4 h-4' />
                                <span>Add Project</span>
                              </div>
                            </WithPermission>
                          </SelectGroup>
                        </SelectContent>
                      </Select>

                      {selectedProject?.id !== 'all' && (
                        <Select value={environment} onValueChange={handleEnvironmentChange}>
                          <SelectTrigger
                            className={`w-[100px] md:w-[200px] ${
                              environment === 'sandbox'
                                ? 'bg-red-200 dark:bg-red-400 dark:text-white'
                                : environment === 'live'
                                ? 'bg-green-200 dark:bg-emerald-600 dark:text-white'
                                : ''
                            }`}
                          >
                            <SelectValue>
                              <div className='flex items-center gap-2'>
                                {environment.charAt(0).toUpperCase() + environment.slice(1)}
                              </div>
                            </SelectValue>
                          </SelectTrigger>

                          <SelectContent className='bg-white'>
                            <div className='flex flex-col gap-2'>
                              <SelectItem value='sandbox' className='flex items-center py-2'>
                                <div className='flex items-center gap-2'>
                                  <div className='w-2 h-2 rounded-full bg-red-600' />
                                  <span className='text-black'>Sandbox</span>
                                </div>
                              </SelectItem>

                              <SelectItem value='live' className='flex items-center py-2'>
                                <div className='flex items-center gap-2'>
                                  <div className='w-2 h-2 rounded-full bg-green-500 dark:bg-emerald-400' />
                                  <span className='text-black'>Live</span>
                                </div>
                              </SelectItem>
                            </div>
                          </SelectContent>
                        </Select>
                      )}
                    </div>
                  ) : (
                    <WithPermission resource='project' action='create'>
                      <div className='flex h-10 w-48 mb-2 mt-2 text-md'>
                        <div
                          className='gap-3 flex justify-start text-white bg-violet-500 px-2 items-center w-[100%] rounded-md'
                          onClick={handleAddProject}
                        >
                          <Plus className='' style={{ fontSize: '1rem' }} />
                          <div className='flex right-0'>Add Project</div>
                        </div>
                      </div>
                    </WithPermission>
                  )}
                </>
              )}
            </>
          )}
          <UserDropdown />
        </div>

        <Dialog open={openSelectTech} onClose={() => setOpenSelectTech(false)} className='z-50'>
          <SelectTechstack
            setOpenProjectDetails={setOpenProjectDetails}
            handleClose={() => setOpenSelectTech(false)}
            projectDetails={projectDetails}
            setProjectDetails={setProjectDetails}
            setOpenSelectTech={setOpenSelectTech}
          />
        </Dialog>

        <Dialog open={openProjectDetails} onClose={() => setOpenProjectDetails(false)}>
          <ProjectDetails
            projectDetails={projectDetails}
            title='Create new project'
            buttonText='Create Project'
            handleClose={() => {
              setOpenProjectDetails(false)
              setOpenSelectTech(false)
            }}
            variant={variant}
          />
        </Dialog>
      </div>
    </header>
  )
}

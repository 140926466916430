//@ts-nocheck
// ** React Imports
import { createContext, useEffect, useState, ReactNode } from 'react'

// ** Next Import
import { useRouter } from 'next/router'

// ** Axios
import axios from 'axios'

// ** Config
import authConfig from 'src/configs/auth'

// **axios
import { createAxiosInstance } from "../configs/axios"

// ** Types
import {
  AuthValuesType,
  LoginParams,
  ErrCallbackType,
  UserDataType,
  newUserRegisters,
  getUsers,
  SignUpParams,
  ResetPasswordParams,
  verifyOtpParams,
  AdminOauth,
  projectDetails,
  userProfileDetails,
  transactionCountParams,
  getTransactionListParams,
  getMAU,
  getInvoices,
  generatePaymentLinkParams,
  addMemberParams,
  SendMessageParams,
  getConsolidatedMetricsParams,
  updateSandboxStepsParams,
  allClientsDataParams,
  userDetails
} from './types'
import { useSelector } from 'react-redux'
import { useAxios } from 'src/hooks/useAxios'
import toast from 'react-hot-toast'
import { useDispatch } from 'react-redux'
import { updateAllProjects, updateSelectedClientId, updateSelectedProject, updateUserProfileData } from 'src/redux/user/userSlice'
import { getPendingClients } from 'src/redux/user/adminSlice'
import { RootState } from 'src/redux/store'

// ** Defaults
const defaultProvider: AuthValuesType = {
  baseURL: '',
  user: null,
  loading: false,
  setUser: () => null,
  setLoading: () => Boolean,
  organizationId: undefined,
  setClientId: () => String,
  getRoles: () => Promise.resolve(),
  login: () => Promise.resolve(),
  handleSignUp: () => Promise.resolve(),
  getTypeFormResponse: () => Promise.resolve(),
  handleNoTokenSignUp: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
  verifyOtp: () => Promise.resolve(),
  AdminOauth: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  updateUserProfile: () => Promise.resolve(),
  getUserProfile: () => Promise.resolve(),
  getNewRegisteredUsers: () => Promise.resolve(),
  getUsers: () => Promise.resolve(),
  getUsersByRange: () => Promise.resolve(),
  getTransactionList: () => Promise.resolve(),
  getActiveUsers: () => Promise.resolve(),
  getRegisteredOrVerifiedCount: () => Promise.resolve(),
  getGroupedDataOfCharts: () => Promise.resolve(),
  saveProjectDetails: () => Promise.resolve(),
  getProjectsData: () => Promise.resolve(),
  getTransactionsCount: () => Promise.resolve(),
  deleteProject: () => Promise.resolve(),
  getMAU: () => Promise.resolve(),
  getPlans: () => Promise.resolve(),
  getInvoices: () => Promise.resolve(),
  sendMessage: () => Promise.resolve(),
  generatePaymentLink: () => Promise.resolve(),
  addMember: () => Promise.resolve(),
  gerMembers: () => Promise.resolve(),
  deleteMember: () => Promise.resolve(),
  getClients: () => Promise.resolve(),
  checkEmail: () => Promise.resolve(),
  setProjectId: () => String,
  approveClient: () => Promise.resolve(),
  fetchClientsData: () => Promise.resolve(),
  getConsolidatedMetrics: () => Promise.resolve(),
  deactivateClient: () => Promise.resolve(),
  updateUserDetails: () => Promise.resolve(),
  projectId: ''
}

const AuthContext = createContext(defaultProvider)

type Props = {
  children: ReactNode
}

const AuthProvider = ({ children }: Props) => {
  // ** States
  const [user, setUser] = useState<UserDataType | null>(defaultProvider.user)
  const [loading, setLoading] = useState<boolean>(defaultProvider.loading)

  const organizationId = useSelector((state: RootState) => state.user.selectedClientId)
  const [projectId, setProjectId] = useState<string | undefined>()
  const baseURL = process.env.NEXT_PUBLIC_BACKEND_URL;
  const dispatch = useDispatch();

  const { axiosInstance, axiosState } = useAxios();

  // const [clientId, setClientId] = useState<string>('b48d8230-57f9-43fb-a952-722668bb3520')
  // const baseURL = 'https://prod.tria.so'
  // const baseURL = 'http://localhost:8000'

  // ** Hooks
  const router = useRouter()

  // const fetchProjectData = async () => {
  //   try {
  //     const response = await getProjectsData();
  //     if (response.success) {
  //       dispatch(updateAllProjects(response.response))
  //       dispatch(updateSelectedProject(response.response[0]))
  //     }
  //   } catch (error) {
  //     toast(`${error}`)
  //   }
  // }

  // useEffect(() => {
  // const initAuth = async (): Promise<void> => {
  // const storedToken =  window.localStorage.getItem(authConfig.storageTokenKeyName)!
  // if (storedToken) {
  // setLoading(true)
  // await axios
  // .get(authConfig.meEndpoint, {
  // headers: {
  // Authorization: storedToken
  // }
  //         })
  // .then(async response => {
  // setLoading(false)
  // console.log(' response.data.userData', response.data.userData)
  // setUser({ ...response.data.userData })
  // })
  // .catch(() => {
  // localStorage.removeItem('userData')
  // localStorage.removeItem('refreshToken')
  // localStorage.removeItem('accessToken')
  // setUser(null)
  // setLoading(false)
  // if (authConfig.onTokenExpiration === 'logout' && !router.pathname.includes('login')) {
  // // router.replace('/login')
  // }
  //         })
  // } else {
  // setLoading(false)
  // }
  //     // setUser({
  // //   id: 1,
  // //   role: "",
  // //   email: "",
  // //   fullName: "",
  // //   username: "",
  // //   password: "",
  // // });
  // // setLoading(false)
  // // router.replace('/dashboard/')
  // }

  // initAuth()
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  useEffect(() => {
    const initAuth = async (): Promise<void> => {
      const storedToken = window.localStorage.getItem(authConfig.storageTokenKeyName)
      console.log('storedToken ', storedToken)
      if (storedToken) {
        // setLoading(true)
        // await axios
        // .get(authConfig.meEndpoint, {
        // headers: {
        // Authorization: storedToken
        // }
        //         })
        // .then(async response => {
        // setLoading(false)
        const userData = localStorage.getItem('userData') || ''
        let parsedUser

        try {
          parsedUser = JSON.parse(userData)
          setUser(parsedUser)
        } catch (error) {
          // Handle  the error,  such as setting parsedUser to an empty object
          console.error('Error parsing user data:', error)
        }

        // })
        // .catch(() => {
        // localStorage.removeItem('userData')
        // localStorage.removeItem('refreshToken')
        // localStorage.removeItem('accessToken')
        // setUser(null)
        // setLoading(false)
        // if (authConfig.onTokenExpiration === 'logout' && !router.pathname.includes('login')) {
        // router.replace('/login')
        // }
        // })
      } else {
        setLoading(false)
      }
      // setUser({
      //   id: 1,
      //   role: "",
      //   email: "",
      //   fullName: "",
      //   username: "",
      //   password: "",
      // });
      // setLoading(false)
      // router.replace('/dashboard/home')
    }

    initAuth()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // fetchProjectData();
  }, [])

  // useEffect(() => {
  //   if (!localStorage.getItem('token') || userProfileData.role !== "triaadmin")
  //     return;
  //   const interval = setInterval(() => {
  //     dispatch(getPendingClients());
  //   }, 30000)

  //   return () => clearInterval(interval);
  // }, [])

  const storeToken = async (response: any) => {
    window.localStorage.setItem(authConfig.storageTokenKeyName, response?.token)
    const { id, token, username, verified, clientId } = response
    // dispatch(updateSelectedClientId(clientId))
    setUser({
      role: 'admin',
      id: id,
      username: username,
      password: '',
      clientId
    })
    window.localStorage.setItem('userData', JSON.stringify({ ...response.data, role: 'admin' }))
    // window.close();
    // if (window.opener) {
    //   // Perform any  necessary actions in the parent window/tab
    //   window.opener.postMessage({ type: 'oauth_success', token }, '*');
    // }
  }

  const handleSignUp = async (params: SignUpParams) => {
    try {
      let apiUrl = `/auth/admin/sign-up`

      // Making the POST request using Axios
      console.log("This is axios instance before making request", axiosInstance);
      const response = await axiosInstance.post(apiUrl, {
        email: params.email,
        password: params.password,
      }, {
        headers: {
          'Authorization': `Bearer ${params.token}`,
        },
      })

      // Handling the response data
      if (response.data?.data !== undefined) {
        const { token, orgDetails, role, email } = response.data?.data
        // Handling the response data
        console.log('Response Data:', response)
        if (token) {
          storeToken(response.data)
          // if (!orgDetails?.organizationName && role.name == 'owner') {
          if (true) {
            router.replace(`/org?email=${email}`)
          } else {
            router.replace('/dashboards/overview')
          }
        }
      }

      // You can return the response data or perform other actions based on your requirements
      return response.data
    } catch (err) {
      console.log('error', err)
      throw err
    }
  }

  const handleNoTokenSignUp = async (email: string) => {
    try {
      const apiUrl = `/auth/send-signup-otp`

      const response = await axiosInstance?.post(apiUrl, {
        email: email,
      });
      return response.data
    } catch (error) {
      console.error(error);
      throw error
    }
  }

  const handleLogin = async (params: LoginParams) => {
    try {
      // const apiUrl = `/auth/admin/sign-in`
      const apiUrl = '/auth/sign-in'

      // Making the POST request using Axios
      const response = await axiosInstance.post(apiUrl, params)
      console.log('verified', response.data)
      if (response.data?.token) {
        const { token, userData } = response.data;
        storeToken(response.data)
        if (token) {
          storeToken({ id: response?.data?.userData.userId, token, username: `${response?.data?.userData?.user?.firstName} ${response?.data?.userData?.user?.lastName}`, verified: response?.data?.userData?.organization?.verified, clientId: response?.data?.userData?.organization?.id })
          if (!userData?.organization?.organizationDetails?.organizationSize && userData?.role?.name == 'owner') {
            router.replace(`/org?email=${userData?.user?.email}`)
          } else {
            router.replace('/dashboards/overview')
          }
        }

      }
      if (response?.data?.redirect === true)
        router.push(`/verification?email=${email}`)
      // Handling the response data
      console.log('Response Data:', response.data)

      // You can return the response data or perform other actions based on your requirements
      return response.data
    } catch (err) {
      console.log('error', err)
      throw err
    }
  }
  const resetPassword = async (params: ResetPasswordParams) => {
    try {
      const apiUrl = `/auth/admin/reset-password`

      // Making the POST request using Axios
      const response = await axiosInstance.post(apiUrl, params)

      // Handling the response data
      console.log('Response Data:', response)

      // You can return the response data or perform other actions based on your requirements
      return response.data
    } catch (err) {
      console.log('error', err)
      throw err
    }
  }

  const verifyOtp = async (params: verifyOtpParams) => {
    try {
      const apiUrl = `/auth/verify-signup-otp`

      // Making the POST request using Axios
      const response = await axiosInstance.post(apiUrl, params)
      const { organization, role, user } = response.data?.userData
      const token = response.data?.token;
      // Handling the response data
      console.log('Response Data:', response)
      if (token) {
        storeToken({ id: response?.data?.userData.userId, token, username: `${response?.data?.userData?.user?.firstName} ${response?.data?.userData?.user?.lastName}`, verified: response?.data?.userData?.organization?.verified, clientId: response?.data?.userData?.organization?.id })
        if (!organization?.organizationDetails && role.name == 'owner') {
          router.replace(`/org?email=${user.email}`)
        } else {
          router.replace('/dashboards/overview')
        }
      }
      return response.data
    } catch (err) {
      console.log('error', err)
      throw err
    }
  }

  const AdminOauth = async (params: AdminOauth) => {
    try {
      const apiUrl = `/auth/admin/google/callback?code=${params?.code}&scope=${params?.scope}`
      // const response = await axios.get(
      //   `${baseURL}/api/v2/auth/admin/google/callback?code=${params?.code}&scope=${params?.scope}`
      // )

      // Making the POST request using Axios
      const response = await axiosInstance.get(apiUrl)

      if (response.data?.token) {
        const { token, userData } = response.data;
        storeToken(response.data)
        if (token) {
          storeToken({ id: response?.data?.userData.userId, token, username: `${response?.data?.userData?.user?.firstName} ${response?.data?.userData?.user?.lastName}`, verified: response?.data?.userData?.organization?.verified, clientId: response?.data?.userData?.organization?.id })
          if (!userData?.organization?.organizationDetails?.organizationSize && userData?.role?.name == 'owner') {
            router.replace(`/org?email=${userData?.user?.email}`)
          } else {
            router.replace('/dashboards/overview')
          }
        }
        return { exist: true, isVerified: true };
      }
      // Handling the response data
      console.log('Response Data:', response)

      // You can return the response data or perform other actions based on your requirements
      return response.data
    } catch (err) {
      console.log('error', err)
      throw err
    }
  }

  // axios
  //   .post(authConfig.loginEndpoint, params)
  //   .then(async response => {
  //     params.rememberMe
  //       ? window.localStorage.setItem(authConfig.storageTokenKeyName, response.data.accessToken)
  //       : null
  //     const returnUrl = router.query.returnUrl

  //     setUser({ ...response.data.userData })
  //     params.rememberMe
  //       ? window.localStorage.setItem(
  //           'userData',
  //           JSON.stringify({
  //             id: 1,
  //             role: 'admin',
  //             fullName: 'John Doe',
  //             username: 'johndoe',
  //             email: 'admin@vuexy.com'
  //           })
  //         )
  //       : null

  //     const redirectURL = returnUrl && returnUrl !== '/' ? returnUrl : '/'

  //     router.replace(redirectURL as string)
  //   })

  //   .catch(err => {
  //     if (errorCallback) errorCallback(err)
  //   })

  const handleLogout = () => {
    setUser(null)
    window.localStorage.removeItem('userData')
    window.localStorage.removeItem(authConfig.storageTokenKeyName)
    router.push('/auth')
  }

  const updateUserProfile = async (params: Partial<UserDataType["organization"]["organizationDetails"] & { email: string }>) => {
    try {
      // const apiUrl = '/auth/admin/update'
      const apiUrl = '/org/update'
      const payload = {
        values: params
      }

      // Making the POST request using Axios
      const response = await axiosInstance.put(apiUrl, payload)

      console.log('Project details saved:', response.data)
      return response.data
    } catch (error) {
      console.error('Error saving project details:', error)
      throw error
    }
  }

  const updateUserDetails = async (params: Partial<userDetails>) => {
    try {
      const apiUrl = '/user/update'
      const payload = {
        values: params
      }
      const response = await axiosInstance.post(apiUrl, payload);
      return response.data;
    } catch (error) {
      toast(error.message);
    }
  }

  const getUserProfile = async () => {
    try {
      const apiUrl = '/user'

      // Making the POST request using Axios
      const response = await axiosInstance.get(apiUrl)

      return response.data;
    } catch (error) {
      console.error('Error saving project details:', error)
      throw error
    }
  }

  const getNewRegisteredUsers = async (params: newUserRegisters) => {
    try {
      // Replace 'API_URL' with your actual API endpoint
      const apiUrl = `/analytics/getUserActivity`

      // Making the POST request using Axios
      const response = await axiosInstance.post(apiUrl, params, {
        headers: organizationId && {
          organizationid: organizationId
        }
      })

      // Handling the response data
      console.log('Response Data: ', response.data)

      // You can return the response data or perform other actions based on your equirements
      return response.data
    } catch (error) {
      // Handling errors
      console.error('Error making POST request:', error)

      // You can throw the error or handle it in another way based on your requirements
      // throw error;
    }
  }

  const getUsers = async (params: getUsers) => {
    try {
      // Replace 'API_URL' with your actual API endpoint
      const apiUrl = `/analytics/getUsers`

      // Making the POST request using Axios
      const response = await axiosInstance.post(apiUrl, params, {
        headers: organizationId && {
          organizationid: organizationId
        }
      })

      // Handling the response data
      console.log('Response Data:', response.data)

      // You can return the response data or perform other actions based on your requirements
      return response.data
    } catch (error) {
      // Handling errors
      console.error('Error making POST request:', error)

      // You can throw the error or handle it in another way based on your requirements
      throw error
    }
  }

  const getUsersByRange = async (params: getUsers) => {
    try {
      // Replace 'API_URL' with your actual API endpoint
      const apiUrl = `/analytics/getUsersByRange`

      // Making the POST request using Axios
      const response = await axiosInstance.post(apiUrl, params)

      // Handling the response data
      console.log('Response Data:', response.data)

      // You can return the response data or perform other actions based on your requirements
      return response.data
    } catch (error) {
      // Handling errors
      console.error('Error making POST request:', error)

      // You can throw the error or handle it in another way based on your requirements
      throw error
    }
  }

  const getActiveUsers = async (params: getUsers) => {
    try {
      // Replace 'API_URL' with your actual API endpoint
      const apiUrl = `/analytics/getUserInteractions`

      // Making the POST request using Axios
      const response = await axiosInstance.post(apiUrl, params, {
        headers: organizationId && {
          organizationid: organizationId
        }
      })

      // Handling the response data
      console.log('Response Data:', response.data)

      // You can return the response data or perform other actions based on your requirements
      return response.data
    } catch (error) {
      // Handling errors
      console.error('Error making POST request:', error)

      // You can throw the error or handle it in another way based on your requirements
      throw error
    }
  }

  const getRegisteredOrVerifiedCount = async (params: getUsers) => {
    try {
      // Replace 'API_URL' with your actual API endpoint
      const apiUrl = `/analytics/getRegisteredOrVerifiedCount`

      // Making the POST request using Axios
      console.log("This is axios instance before making request", axiosInstance);
      console.log("API URL:", apiUrl);
      console.log("Params:", params);
      const response = await axiosInstance.post(apiUrl, params, {
        headers: organizationId && {
          organizationid: organizationId
        }
      })

      // Handling the response data
      console.log('Response Data:', response.data)

      // You can return the response data or perform other actions based on your requirements
      return response.data.data
    } catch (error) {
      // Handling errors
      console.error('Error making POST request:', error)

      // You can throw the error or handle it in another way based on your requirement
      throw error
    }
  }

  const getGroupedDataOfCharts = async (params: getUsers) => {
    try {
      // Replace 'API_URL' with your actual API endpoint
      const apiUrl = `/analytics/groupUsersByPlatform`

      // Making the POST request using Axios
      const response = await axiosInstance.post(apiUrl, params, {
        headers: organizationId && {
          organizationid: organizationId
        }
      })

      // Handling the response data
      console.log('Response Data:', response.data)

      // You can return the response data or perform other actions based on your requirements
      return response.data.data
    } catch (error) {
      // Handling errors
      console.error('Error making POST request:', error)

      // You can throw the error or handle it in another way based on your requirements
      throw error
    }
  }

  const getTransactionsCount = async (params: transactionCountParams) => {
    try {
      // Replace 'API_URL' with your actual API endpoint
      const apiUrl = `/analytics/getTransactionsCount`

      // Making the POST request using Axios
      const response = await axiosInstance.post(apiUrl, params, {
        headers: organizationId && {
          organizationid: organizationId
        }
      })

      // Handling the response data
      console.log('Response Data:', response.data)

      // You can return the response data or perform other actions based on your requirements
      return response.data.data
    } catch (error) {
      // Handling errors
      console.error('Error making POST request:', error)

      // You can throw the error or handle it in another way based on your requirements
      throw error
    }
  }

  const saveProjectDetails = async (params: projectDetails) => {
    try {
      const apiUrl = '/project/create-or-update'
      // const apiUrl = '/analytics/create-or-update-project'

      // Making the POST request using Axios
      const response = await axiosInstance.post(apiUrl, params)

      console.log('Project details saved:', response.data)
      return response.data
    } catch (error) {
      console.error('Error saving project details:', error)
      throw error
    }
  }

  const updateSandboxSteps = async (params: updateSandboxStepsParams) => {
    try {
      const apiUrl = `/analytics/updateSandboxSteps`
      const response = await axiosInstance?.post(apiUrl, params);
      return response?.data
    } catch (error) {
      throw error
    }
  }

  const uploadImage = async (formData: FormData) => {
    try {
      const apiUrl = 'auth/upload/image'

      // Making the POST request using Axios
      const response = await axiosInstance.post(apiUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })

      console.log('Image uploaded:', response.data)
      return response.data
    } catch (error) {
      console.error('Error uploading image:', error)
      throw error
    }
  }

  const getProjectsData = async () => {
    try {
      // const apiUrl = `/analytics/get-projects`
      const apiUrl = `/project`

      // Making the POST request using Axios
      const response = await axiosInstance.get(apiUrl)

      console.log('Response Data:', response.data)

      return response.data
    } catch (error) {
      console.error('Error making POST request:', error)
      throw error
    }
  }

  const deleteProject = async (projectId: string) => {
    try {
      const apiUrl = `/project/${projectId}`
      // const apiUrl = `/analytics/delete-project/${projectId}`

      // Making the POST request using Axios
      const response = await axiosInstance.delete(apiUrl)

      console.log('Response Data: ', response.data)

      return response.data
    } catch (error) {
      console.error('Error making DELETE request:', error)
      throw error
    }
  }

  const getTransactionList = async (params: getTransactionListParams) => {
    try {
      const apiUrl = `/analytics/getTransactionsList`

      // Making the POST request using Axios
      const response = await axiosInstance.post(apiUrl, params, {
        headers: organizationId && {
          organizationid: organizationId
        }
      })

      console.log('Response Data: ', response.data)

      return response.data
    } catch (error) {
      console.error('Error making getTransactionList request:', error)
      throw error
    }
  }

  const getMAU = async (params: getMAU) => {
    try {
      const apiUrl = `/analytics/monthlyActiveUsers`

      // Making the POST request using Axios
      const response = await axiosInstance.post(apiUrl, params)

      console.log('Response Data: ', response.data)

      return response.data
    } catch (error) {
      console.error('Error making getTransactionList request:', error)
      throw error
    }
  }

  const getPlans = async () => {
    try {
      const apiUrl = `/plans`
      // Making the POST request using Axios
      const response = await axiosInstance.get(apiUrl)

      console.log('Response Data: ', response.data)

      return response.data
    } catch (error) {
      console.error('Error making getTransactionList request:', error)
      throw error
    }
  }

  const getInvoices = async (params: getInvoices) => {
    try {
      console.log('params', params)
      const apiUrl = `/payment/invoices`
      // Making the POST request using Axios
      const response = await axiosInstance.post(apiUrl, params)

      console.log('Response Data: ', response.data)

      return response.data
    } catch (error) {
      console.error('Error making getTransactionList request:', error)
      throw error
    }
  }

  const generatePaymentLink = async (params: generatePaymentLinkParams) => {
    try {
      const apiUrl = `/payment/create-checkout-session`
      // Making the POST request using Axios
      const response = await axiosInstance.post(apiUrl, params)

      console.log('Response Data: ', response.data)

      return response.data
    } catch (error) {
      console.error('Error making getTransactionList request:', error)
      throw error
    }
  }

  const sendMessage = async (params: SendMessageParams) => {
    try {
      const apiUrl = '/analytics/sendMessage';
      const payload = params;
      console.log("Message Payload: ", payload)
      const response = await axiosInstance.post(apiUrl, payload);
      return response.data
    } catch (error) {
      console.error('Error making sendMessage request:', error);
      throw error;
    }
    // try {
    //   const response = await axios.post('https://intent.tria.so/broadcast/sendmessage', payload)
    //   return response
    // } catch (error) {
    //   console.error('Error making getTransactionList request:', error)
    //   throw error
    // }
  }

  const addMember = async (params: addMemberParams) => {
    try {
      console.log('params', params)
      const apiUrl = `user/add-member`
      // Making the POST request using Axios
      const response = await axiosInstance.post(apiUrl, params)

      console.log('Response Data: ', response.data)

      return response.data
    } catch (error) {
      console.error('Error making getTransactionList request:', error)
      throw error
    }
  }

  const gerMembers = async () => {
    try {
      const apiUrl = `user/members`
      // Making the POST request using Axios
      const response = await axiosInstance.get(apiUrl)

      console.log('Response Data: ', response.data)

      return response.data
    } catch (error) {
      console.error('Error making getTransactionList request:', error)
      throw error
    }
  }

  const deleteMember = async (userId: string) => {
    try {
      const apiUrl = `user/member/${userId}`
      // Making the POST request using Axios
      const response = await axiosInstance.delete(apiUrl)

      console.log('Response Data: ', response.data)

      return response.data
    } catch (error) {
      console.error('Error making getTransactionList request:', error)
      throw error
    }
  }

  const getClients = async (id?: string) => {
    try {
      const apiUrl = id ? `org/${id}` : 'org/'
      // Making the POST request using Axios
      const response = await axiosInstance.get(apiUrl)

      console.log('Response Data: ', response.data.data)

      return response.data
    } catch (error) {
      console.error('Error making getTransactionList request:', error)
      throw error
    }
  }
  const checkEmail = async (email: string) => {
    try {
      const apiUrl = `auth/email-exists/${email}/`
      // const apiUrl = `auth/admin/email-exists/${email}`

      // Making the POST request using Axios
      const response = await axiosInstance.get(apiUrl)

      console.log('Response Data: ', response.data)

      return response.data
    } catch (error) {
      console.error('Error making getTransactionList request:', error)
      throw error
    }
  }

  const fetchClientsData = async (params: allClientsDataParams) => {
    try {
      const apiUrl = `admin/getAllClientsDetails?page=${params.page}&limit=${params.pageSize}`
      const response = await axiosInstance.get(apiUrl)
      return response.data
    } catch (error) {
      console.error('Error making fetchClientsData request:', error)
      throw error
    }
  }

  const approveClient = async (clientId: string, operation: string) => {
    try {
      const apiUrl = '/auth/admin/authorize-user'
      const response = await axiosInstance.post(apiUrl, { clientId, operation })
      return response.data;
    } catch (error) {
      throw error;
    }
  }


  const getConsolidatedMetrics = async (params: getConsolidatedMetricsParams) => {
    console.log("projectId", params);
    try {
      const apiUrl = `analytics/getConsolidatedMatrices`
      const response = await axiosInstance.post(apiUrl, params, {
        headers: organizationId && {
          organizationid: organizationId
        }
      });
      console.log("response", response);
      return response.data;
    } catch (error) {
      console.error('Error making fetchClientsData request:', error)
      throw error
    }
  }

  const getRoles = async () => {
    try {
      const apiUrl = `/org/roles`;
      const response = await axiosInstance.get(apiUrl);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  const userProfileData = useSelector((state: RootState) => state.user.userProfileData);

  const upgradePlan = async (planId: string) => {
    try {
      const apiUrl = `/org/changePlan/${planId}`
      const response = await axiosInstance.put(apiUrl);
      if (response.data.success) {
        console.log("response.data.plan", response.data.data.plan);
        dispatch(updateUserProfileData({ ...userProfileData, plan: response.data.data.plan }))
      }
      return response.data;
    } catch (error) {
      toast(error.message);
      console.log("Error: ", error);
    }
  }
  const deactivateClient = async (params: deactivateClientParams) => {
    try {
      const apiUrl = `/admin/deactivate-client`
      const response = await axiosInstance.post(apiUrl, params)
      if (response.data.success) {
        console.log("response.data.plan", response.data);
      }
      return response.data;
    } catch (error) {
      toast(error.message);
      console.log("Error: ", error);
    }
  }

  const getTypeFormResponse = async (id: string) => {
    try {
      const apiUrl = `/admin/getPendingClientById?id=${id}`
      const response = await axiosInstance.get(apiUrl)
      return response.data.data
    } catch (error) {
      toast(error.message);
      console.log("Error: ", error);
    }
  }

  const values = {
    baseURL,
    user,
    loading,
    setUser,
    setLoading,
    login: handleLogin,
    handleSignUp,
    handleNoTokenSignUp,
    resetPassword,
    verifyOtp,
    AdminOauth,
    logout: handleLogout,
    updateUserProfile,
    getUserProfile,
    getTypeFormResponse,
    getNewRegisteredUsers,
    getUsers,
    getActiveUsers,
    getRegisteredOrVerifiedCount,
    getGroupedDataOfCharts,
    saveProjectDetails,
    getProjectsData,
    getTransactionsCount,
    deleteProject,
    getUsersByRange,
    updateSandboxSteps,
    getRoles,
    projectId,
    setProjectId,
    getTransactionList,
    getMAU,
    getPlans,
    updateUserDetails,
    getInvoices,
    sendMessage,
    generatePaymentLink,
    addMember,
    gerMembers,
    deleteMember,
    getClients,
    checkEmail,
    uploadImage,
    fetchClientsData,
    getConsolidatedMetrics,
    upgradePlan,
    approveClient,
    deactivateClient
  }

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

export { AuthContext, AuthProvider }

'use client'
import Link from 'next/link'
import { CircleHelp, FileText, MenuIcon, PanelsTopLeft } from 'lucide-react'

import { Button } from '../../components/shadcn/button'
import { Sheet, SheetHeader, SheetContent, SheetTrigger, SheetTitle } from '../../components/shadcn/sheet'
import Menu from './menu'
import { useState } from 'react'
import CustomToggleButton from 'src/views/ui/Switch'
import { useSelector } from 'react-redux'
import { RootState } from 'src/redux/store'
import { shadcn as cn } from 'src/@core/utils/helper-functions'
import MenuMobile from './sheet-menu-mobile'

export function SheetMenu() {
  const [open, setOpen] = useState(false)
  const mode = useSelector((state: RootState) => state.user.darkMode)
  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger className='lg:hidden' asChild>
        <Button className='h-10 shrink-0' variant='outline' size='icon'>
          <MenuIcon size={20} />
        </Button>
      </SheetTrigger>
      <SheetContent className={cn('sm:w-72 px-3 h-full flex flex-col z-[9999] bg-[#24303F]')} side='left'>
        <SheetHeader>
          <Button className='flex justify-center items-center pb-2 pt-1' variant='link' asChild>
            <Link href='/' className='flex items-center gap-2'>
              <PanelsTopLeft className='w-6 h-6 mr-1 text-white' />
              <SheetTitle className='font-bold text-lg text-white'>Tria</SheetTitle>
            </Link>
          </Button>
        </SheetHeader>
        <MenuMobile isOpen setOpen={setOpen} />
        <div className='flex flex-col gap-3 w-full px-4'>
          <Link href='https://calendly.com/avi-tria/meetup'>
            <Button className='flex items-center justify-start gap-2 w-full text-white'>
              <CircleHelp className='w-5 h-5' />
              Need Help
            </Button>
          </Link>
          <Link href='https://docs.tria.so/'>
            <Button className='flex items-center justify-start gap-2 w-full text-white'>
              <FileText className='w-5 h-5' />
              Docs
            </Button>
          </Link>
          <div className={cn('w-full flex items-center justify-start mb-5 text-white')}>
            <CustomToggleButton /> Theme
          </div>
        </div>
      </SheetContent>
    </Sheet>
  )
}
